export const superAdminMenu = [
  {
    name: "Missions",
    link: "/mission",
  },
  { name: "Users", link: "/users" },
  {
    name: "Patient",
    link: "/patient",
  },
  {
    name: "Consultation",
    link: "/consultation",
  },
  // {
  //   name: "Health Education",
  //   link: "/health-edu",
  // },
  // {
  //   name: "Report",
  //   link: "/report",
  // },
  // {
  //   name: "Feeds",
  //   link: "/feeds",
  // },
  // {
  //   name: "ActivityLogs",
  //   link: "/activity-logs",
  // },
  {
    name: "Profile",
    link: "/profile",
  },
];

export const adminMenu = [
  {
    name: "Missions",
    link: "/mission",
  },
  // {
  //   name: "Schedule",
  //   link: "/schedule",
  // },
  {
    name: "Patient",
    link: "/patient",
  },
  // {
  //   name: "Health Education",
  //   link: "/health-edu",
  // },
  // {
  //   name: "Report",
  //   link: "/report",
  // },
  // {
  //   name: "Feeds",
  //   link: "/feeds",
  // },
  {
    name: "Profile",
    link: "/profile",
  },
];

export const doctorMenu = [
  {
    name: "Missions",
    link: "/mission",
  },
  // {
  //   name: "Schedule",
  //   link: "/schedule",
  // },
  {
    name: "Patient",
    link: "/patient",
  },
  {
    name: "Consultation",
    link: "/consultation",
  },
  // {
  //   name: "Surgery",
  //   link: "/surgery",
  // },
  // {
  //   name: "Health Education",
  //   link: "/health-edu",
  // },
  // {
  //   name: "Report",
  //   link: "/report",
  // },
  // {
  //   name: "Feeds",
  //   link: "/feeds",
  // },
  {
    name: "Profile",
    link: "/profile",
  },
];

export const patientMenu = [
  {
    name: "Health Education",
    link: "/health-edu",
  },
  {
    name: "Medical History",
    link: "/medical-history",
  },
  {
    name: "Profile",
    link: "/profile",
  },
];

export const nurseMenu = [
  // {
  //   name: "Admission",
  //   link: "/admission",
  // },
  // {
  //   name: "Surgery",
  //   link: "/surgery-nurse",
  // },
  // {
  //   name: "Health Education",
  //   link: "/health-edu",
  // },
  // {
  //   name: "Report",
  //   link: "/report",
  // },
  // {
  //   name: "Feeds",
  //   link: "/feeds",
  // },
  {
    name: "Profile",
    link: "/profile",
  },
];
export const anethesiaMenu = [
  {
    name: "Health Education",
    link: "/health-edu",
  },
  // {
  //   name: "Report",
  //   link: "/report",
  // },
  // {
  //   name: "Feeds",
  //   link: "/feeds",
  // },
  {
    name: "Profile",
    link: "/profile",
  },
];

export const pharmacistMenu = [
  {
    name: "Dispensing",
    link: "/dispensing",
  },
  {
    name: "Inventory",
    link: "/inventory",
  },
  // {
  //   name: "Health Education",
  //   link: "/health-edu",
  // },
  // {
  //   name: "Report",
  //   link: "/report",
  // },
  // {
  //   name: "Feeds",
  //   link: "/feeds",
  // },
  {
    name: "Profile",
    link: "/profile",
  },
];
export const labMenu = [
  // {
  //   name: "Sample",
  //   link: "/sample",
  // },
  // {
  //   name: "Result",
  //   link: "/result",
  // },
  // {
  //   name: "Feeds",
  //   link: "/feeds",
  // },
  {
    name: "Profile",
    link: "/profile",
  },
];

export const genderData = [
  { id: "Male", name: "Male" },
  { id: "Female", name: "Female" },
  { id: "Others", name: "Others" },
  { id: "Prefer Not to Mention", name: "Prefer Not to Mention" },
];
export const maritialData = [
  { id: "Single", name: "Single" },
  { id: "Married", name: "Married" },
  { id: "Divorced", name: "Divorced" },
  { id: "Widowed", name: "Widowed" },
];
export const signupMedData = [
  { id: "Hypertension", name: "Hypertension" },
  { id: "Diabetes", name: "Diabetes" },
  { id: "Chronic pain", name: "Chronic pain" },
  { id: "Pregnancy", name: "Pregnancy" },
  { id: "Male Concerns", name: "Male Concerns" },
  { id: "Others", name: "Others" },
];
export const mediaData = [
  { id: "Social Media", name: "Social Media" },
  { id: "Flyers", name: "Flyers" },
  { id: "Word of Mouth", name: "Word of Mouth" },
  { id: "Radio/TV", name: "Radio/TV" },
  { id: "Others", name: "Others" },
];

export const bloodGroupData = [
  { name: "A RhD positive (A+)", value: "A RhD positive (A+)" },
  { name: "A RhD negative (A-)", value: "A RhD negative (A-)" },
  { name: "B RhD positive (B+)", value: "B RhD positive (B+)" },
  { name: "B RhD negative (B-)", value: "B RhD negative (B-)" },
  { name: "O RhD positive (O+)", value: "O RhD positive (O+)" },
  { name: "O RhD negative (O-)", value: "O RhD negative (O-)" },
  { name: "AB RhD positive (AB+)", value: "AB RhD positive (AB+)" },
  { name: "AB RhD negative (AB-)", value: "AB RhD negative (AB-)" },
];

export const genotypeData = [
  { name: "AA", value: "AA" },
  { name: "AS", value: "AS" },
  { name: "AC", value: "AC" },
  { name: "SS", value: "SS" },
  { name: "SC", value: "SC" },
];
export const malariaOptions = [
  { name: "Positive", value: "Positive" },
  { name: "Negative", value: "Negative" },
];
export const fastingBloodSugarOptions = [
  { name: "Normal (70-100 mg/dL)", value: "Normal (70-100 mg/dL)" },
  {
    name: "Pre-diabetic (100-125 mg/dL)",
    value: "Pre-diabetic (100-125 mg/dL)",
  },
  {
    name: "Diabetic (126 mg/dL or higher)",
    value: "Diabetic (126 mg/dL or higher)",
  },
];
export const pulseData = [
  { name: "Regular", value: "Regular" },
  { name: "Irregular", value: "Irregular" },
  { name: "Strong", value: "Strong" },
  { name: "Weak", value: "Weak" },
];

export const pulseOximeterData = [
  { name: "O2", value: "O2" },
  { name: "Room Air", value: "Room Air" },
];
export const allergyDatas = [
  { value: "1", name: "Food Allergies" },
  { value: "2", name: "Drug Allergies" },
  { value: "3", name: "Environmental Allergies" },
];

export const bloodpressureData = [
  { name: "Left Hand", value: "Left Hand" },
  { name: "Right Hand", value: "Right Hand" },
  { name: "Standing", value: "Standing" },
  { name: "Sitting", value: "Sitting" },
  { name: "Lying", value: "Lying" },
];
export const heightData = [
  { name: "m", value: "m" },
  { name: "cm", value: "cm" },
];

export const temperatureData = [
  { name: "Oral", value: "Oral" },
  { name: "Axillary", value: "Axillary" },
  { name: "Rectal", value: "Rectal" },
  { name: "Tympanic", value: "Tympanic" },
];
export const hepatitisBOptions = [
  { name: "HBsAg", value: "HBsAg" },
  { name: "HBsAb", value: "HBsAb" },
  { name: "HBeAg", value: "HBeAg" },
  { name: "HBeAb", value: "HBeAb" },
  { name: "HBcAb", value: "HBcAb" },
];
export const hemoglobinA1cOptions = [
  { name: "Normal", value: "Normal" },
  { name: "High", value: "High" },
  { name: "Low", value: "Low" },
];
export const titleData = [
  { name: "Mr", value: "Mr" },
  { name: "Mrs", value: "Mrs" },
  { name: "Miss", value: "Miss" },
  { name: "Dr", value: "Dr" },
  { name: "Prof", value: "Prof" },
];
export const compProfileOptions = [
  { name: "Glucose", value: "Glucose" },
  {
    name: "Electrolytes (Sodium, Potassium, Chloride)",
    value: "Electrolytes (Sodium, Potassium, Chloride)",
  },
  {
    name: "Kidney Function (Creatinine, Blood Urea Nitrogen)",
    value: "Kidney Function (Creatinine, Blood Urea Nitrogen)",
  },
  {
    name: "Liver Function (ALT, AST, Bilirubin)",
    value: "Liver Function (ALT, AST, Bilirubin)",
  },
  { name: "Total Protein", value: "Total Protein" },
  { name: "Albumin", value: "Albumin" },
];
export const cbcOptions = [
  {
    name: "White Blood Cell Count (WBC)",
    value: "White Blood Cell Count (WBC)",
  },
  { name: "Red Blood Cell Count (RBC)", value: "Red Blood Cell Count (RBC)" },
  { name: "Hemoglobin (HGB)", value: "Hemoglobin (HGB)" },
  { name: "Hematocrit (HCT)", value: "Hematocrit (HCT)" },
  { name: "Platelet Count", value: "Platelet Count" },
];
export const relationshipData = [
  { name: "Parent", value: "Parent" },
  { name: "Sibling", value: "Sibling" },
  { name: "Children", value: "Children" },
  { name: "Friend", value: "Friend" },
  { name: "Relative", value: "Relative" },
  { name: "Spouse", value: "Spouse" },
  { name: "Colleague", value: "Colleague" },
];

export const testLabData = [
  { name: "Radiological Investigation", value: "1" },
  { name: "Chemistry", value: "2" },
  { name: "Microbiology", value: "3" },
  { name: "Histopathology", value: "4" },
  { name: "Haematology", value: "5" },
];

export const amountOfDaysData = [
  { name: "1 Day", value: "1 Day" },
  { name: "2 Days", value: "2 Days" },
  { name: "3 Days", value: "3 Days" },
  { name: "4 Days", value: "4 Days" },
  { name: "5 Days", value: "5 Days" },
  { name: "6 Days", value: "6 Days" },
  { name: "1 Week", value: "1 Week" },
  { name: "2 Weeks", value: "2 Weeks" },
  { name: "3 Weeks", value: "3 Weeks" },
  { name: "1 Month", value: "1 Month" },
  { name: "2 Months", value: "2 Months" },
  { name: "3 Months", value: "3 Months" },
  { name: "4 Months", value: "4 Months" },
  { name: "5 Months", value: "5 Months" },
  { name: "6 Months", value: "6 Months" },
];

export const usageData = [
  { name: "Once A Day", value: "Once A Day" },
  { name: "Twice a Day", value: "Twice a Day" },
  { name: "Thrice a Day", value: "Thrice a Day" },
];

export const dosageData = [
  { name: "none", value: "none" },
  { name: "0.15mg", value: "0.15mg" },
  { name: "0.3mg", value: "0.3mg" },
  { name: "5mg", value: "5mg" },
  { name: "10mg", value: "10mg" },
  { name: "20mg", value: "20mg" },
  { name: "50mg", value: "50mg" },
  { name: "81mg", value: "81mg" },
  { name: "400mg", value: "400mg" },
  { name: "80mg", value: "80mg" },
  { name: "100mg", value: "100mg" },
  { name: "150mg", value: "150mg" },
  { name: "200mg", value: "200mg" },
  { name: "250mg", value: "250mg" },
  { name: "500mg", value: "500mg" },
  { name: "0.15ml", value: "0.15ml" },
  { name: "0.3ml", value: "0.3ml" },
  { name: "1ml", value: "1ml" },
  { name: "2.5ml", value: "2.5ml" },
  { name: "5ml", value: "5ml" },
  { name: "10ml", value: "10ml" },
  { name: "20ml", value: "20ml" },
  { name: "25ml", value: "25ml" },
  { name: "30ml", value: "30ml" },
  { name: "50ml", value: "50ml" },
  { name: "75ml", value: "75ml" },
  { name: "100ml", value: "100ml" },
];
export const drugPackagings = [
  { name: "Bottle", value: "Bottle" },
  { name: "Blister Pack", value: "Blister Pack" },
  { name: "Box", value: "Box" },
  { name: "Tube", value: "Tube" },
  { name: "Vial", value: "Vial" },
  { name: "Ampoule", value: "Ampoule" },
  { name: "Jar", value: "Jar" },
  { name: "Sachet", value: "Sachet" },
  { name: "Carton", value: "Carton" },
  { name: "Syringe", value: "Syringe" },
  { name: "Canister", value: "Canister" },
  { name: "Pouch", value: "Pouch" },
  { name: "Dispenser", value: "Dispenser" },
  { name: "Bag", value: "Bag" },
  { name: "Packet", value: "Packet" },
  { name: "Strip", value: "Strip" },
  { name: "Dropper Bottle", value: "Dropper Bottle" },
  { name: "Spray Bottle", value: "Spray Bottle" },
  { name: "Tin", value: "Tin" },
  { name: "Tube with Applicator", value: "Tube with Applicator" },
];
export const dischargeData = [
  { name: "Home", value: "Home" },
  { name: "Referred to another center ", value: "Referred to another center " },
];
export const drugForms = [
  { name: "Tablet", value: "Tablet" },
  { name: "Capsule", value: "Capsule" },
  { name: "Liquid", value: "Liquid" },
  { name: "Injection", value: "Injection" },
  { name: "Cream", value: "Cream" },
  { name: "Ointment", value: "Ointment" },
  { name: "Syrup", value: "Syrup" },
  { name: "Drops", value: "Drops" },
  { name: "Inhaler", value: "Inhaler" },
  { name: "Patch", value: "Patch" },
  { name: "Suppository", value: "Suppository" },
  { name: "Gel", value: "Gel" },
  { name: "Powder", value: "Powder" },
  { name: "Lozenge", value: "Lozenge" },
  { name: "Spray", value: "Spray" },
  { name: "Suspension", value: "Suspension" },
  { name: "Emulsion", value: "Emulsion" },
  { name: "Granule", value: "Granule" },
  { name: "Lotion", value: "Lotion" },
  { name: "Solution", value: "Solution" },
];
export const sampleData = [
  { name: "Blood", value: "Blood" },
  { name: "Swap", value: "Swap" },
  { name: "Urine", value: "Urine" },
  { name: "Sperm", value: "Sperm" },
  { name: "Tissues", value: "Tissues" },
  { name: "Stool", value: "Stool" },
  { name: "Saliva", value: "Saliva" },
];
export const painData = [
  { name: "1", value: "1" },
  { name: "2", value: "2" },
  { name: "3", value: "3" },
  { name: "4", value: "4" },
  { name: "5", value: "5" },
  { name: "6", value: "6" },
  { name: "7", value: "7" },
  { name: "8", value: "8" },
  { name: "9", value: "9" },
  { name: "10", value: "10" },
];
export const surgeryAccData = [
  { name: "Normal", value: "Normal" },
  { name: "Abnormal", value: "Abnormal" },
  { name: "Others", value: "Others" },
];

export const medicalProcedures = [
  { name: "Cholecystectomy", value: "Cholecystectomy" },
  { name: "Rhinoplasty", value: "Rhinoplasty" },
  { name: "Lumpectomy", value: "Lumpectomy" },
  { name: "Craniotomy", value: "Craniotomy" },
  { name: "Cataract surgery", value: "Cataract surgery" },
  { name: "LASIK", value: "LASIK" },
  { name: "PRK", value: "PRK" },
  { name: "Retinal detachment surgery", value: "Retinal detachment surgery" },
  { name: "Glaucoma surgery", value: "Glaucoma surgery" },
  { name: "Vitrectomy", value: "Vitrectomy" },
  { name: "Corneal transplant surgery", value: "Corneal transplant surgery" },
  { name: "Thoracotomy", value: "Thoracotomy" },
  { name: "Lobectomy", value: "Lobectomy" },
  { name: "Pneumonectomy", value: "Pneumonectomy" },
  { name: "Mediastinoscopy", value: "Mediastinoscopy" },
  { name: "Thoracoscopy", value: "Thoracoscopy" },
  {
    name: "Coronary artery bypass graft (CABG)",
    value: "Coronary artery bypass graft (CABG)",
  },
  {
    name: "Lung volume reduction surgery",
    value: "Lung volume reduction surgery",
  },
  { name: "Pleurectomy", value: "Pleurectomy" },
  { name: "Esophagectomy", value: "Esophagectomy" },
  { name: "Pericardiectomy", value: "Pericardiectomy" },
];

export const treatmentPlanOptions = [
  { name: "Medication", value: "Medication" },
  { name: "Therapy", value: "Therapy" },
  { name: "Surgery", value: "Surgery" },
  { name: "Lifestyle changes", value: "Lifestyle changes" },
  { name: "Referral to specialist", value: "Referral to specialist" },
  { name: "Follow-up appointment", value: "Follow-up appointment" },
];

export const behavioralNoteOptions = [
  { name: "Encourage exercise", value: "Encourage exercise" },
  {
    name: "Suggest stress management techniques",
    value: "Suggest stress management techniques",
  },
  { name: "Discuss diet changes", value: "Discuss diet changes" },
  { name: "Advise smoking cessation", value: "Advise smoking cessation" },
  {
    name: "Recommend dietary adjustments",
    value: "Recommend dietary adjustments",
  },
  {
    name: "Encourage stress reduction techniques",
    value: "Encourage stress reduction techniques",
  },
];

export const admissionNoteOptions = [
  {
    name: "Admitted for further observation",
    value: "Admitted for further observation",
  },
  { name: "Advised to monitor symptoms", value: "Advised to monitor symptoms" },
  {
    name: "Admitted for surgical procedure",
    value: "Admitted for surgical procedure",
  },
  {
    name: "Advised to continue observation at home",
    value: "Advised to continue observation at home",
  },
  { name: "No admission necessary", value: "No admission necessary" },
  {
    name: "Discharged with instructions",
    value: "Discharged with instructions",
  },
];

export const assessmentOptions = [
  {
    name: "High blood pressure detected",
    value: "High blood pressure detected",
  },
  { name: "Additional testing", value: "Anxiety disorder" },
  { name: "Appendicitis", value: "Appendicitis" },
  {
    name: "Chronic obstructive pulmonary disease (COPD)",
    value: "Chronic obstructive pulmonary disease (COPD)",
  },
  { name: "Gastritis", value: "Gastritis" },
  { name: "Depression", value: "Depression" },
];
export const planxzOptions = [
  {
    name: "Medication / Adjustment",
    value: "Medication / Adjustment",
  },
  { name: "Additional testing", value: "Additional testing" },
  { name: "Refer to specialist", value: "Refer to specialist" },
  {
    name: "Therapy or rehabilitation",
    value: "Therapy or rehabilitation",
  },
  { name: "Discharge planning", value: "Discharge planning" },
];

export const presentationOptions = [
  { name: "Alert and responsive", value: "Alert and responsive" },
  { name: "Anxious", value: "Anxious" },
  {
    name: "Pain in the lower right abdomen",
    value: "Pain in the lower right abdomen",
  },
  { name: "Shortness of breath", value: "Shortness of breath" },
  { name: "Abdominal discomfort", value: "Abdominal discomfort" },
  { name: "Low mood and lack of energy", value: "Low mood and lack of energy" },
];

export const planOptions = [
  {
    name: "Prescribe medication and monitor",
    value: "Prescribe medication and monitor",
  },
  {
    name: "Refer to therapist for cognitive-behavioral therapy",
    value: "Refer to therapist for cognitive-behavioral therapy",
  },
  { name: "Prepare for appendectomy", value: "Prepare for appendectomy" },
  {
    name: "Refer to pulmonary rehabilitation program",
    value: "Refer to pulmonary rehabilitation program",
  },
  {
    name: "Refer to gastroenterologist for further evaluation",
    value: "Refer to gastroenterologist for further evaluation",
  },
  {
    name: "Schedule follow-up appointment with psychiatrist",
    value: "Schedule follow-up appointment with psychiatrist",
  },
];

export const assessmentOptions2 = [
  {
    name: "Probable acute appendicitis with rebound tenderness and localized abdominal pain",
    value:
      "Probable acute appendicitis with rebound tenderness and localized abdominal pain",
  },
  {
    name: "Suspected pulmonary embolism due to sudden onset of dyspnea and pleuritic chest pain",
    value:
      "Suspected pulmonary embolism due to sudden onset of dyspnea and pleuritic chest pain",
  },
  {
    name: "Likely gastroenteritis based on symptoms of nausea, vomiting, and diarrhea with recent food intake",
    value:
      "Likely gastroenteritis based on symptoms of nausea, vomiting, and diarrhea with recent food intake",
  },
  {
    name: "Possible concussion following head trauma with brief loss of consciousness and post-traumatic amnesia",
    value:
      "Possible concussion following head trauma with brief loss of consciousness and post-traumatic amnesia",
  },
  {
    name: "Suspected acute cholecystitis with right upper quadrant abdominal pain, fever, and positive Murphy's sign",
    value:
      "Suspected acute cholecystitis with right upper quadrant abdominal pain, fever, and positive Murphy's sign",
  },
];
export const hpiOptions = [
  {
    name: "Respiratory",
    value: "Respiratory: cough, shortness of breath, wheezing",
  },
  {
    name: "Cardiovascular",
    value: "Cardiovascular: chest pain, palpitations, swelling of the ankles",
  },
  {
    name: "Gastrointestinal",
    value:
      "Gastrointestinal: abdominal pain, nausea, vomiting, changes in bowel habits",
  },
  {
    name: "Neurological",
    value: "Neurological: headache, dizziness, weakness, numbness",
  },
  {
    name: "Musculoskeletal",
    value: "Musculoskeletal: joint pain, muscle weakness, limited mobility",
  },
  {
    name: "Endocrine",
    value: "Endocrine: changes in weight, thirst, hunger, fatigue",
  },
  {
    name: "Dermatological",
    value: "Dermatological: rashes, itching, skin changes",
  },
];
export const pastMedicalHistoryOptions = [
  { name: "Hypertension", value: "Hypertension" },
  { name: "Diabetes mellitus", value: "Diabetes mellitus" },
  { name: "Hyperlipidemia", value: "Hyperlipidemia" },
  { name: "Asthma", value: "Asthma" },
  { name: "Arthritis", value: "Arthritis" },
  { name: "Thyroid disorder", value: "Thyroid disorder" },
  { name: "Cancer", value: "Cancer (specify type)" },
  { name: "Heart disease", value: "Heart disease" },
  { name: "Stroke", value: "Stroke" },
  { name: "Kidney disease", value: "Kidney disease" },
  { name: "Liver disease", value: "Liver disease" },
];

export const pastSurgicalHistoryOptions = [
  { name: "Appendectomy", value: "Appendectomy" },
  { name: "Cholecystectomy", value: "Cholecystectomy" },
  { name: "Hysterectomy", value: "Hysterectomy" },
  { name: "Hernia repair", value: "Hernia repair" },
  { name: "Joint replacement", value: "Joint replacement" },
  { name: "Cardiac bypass surgery", value: "Cardiac bypass surgery" },
  { name: "Orthopedic surgery", value: "Orthopedic surgery" },
  { name: "Cancer surgery", value: "Cancer surgery" },
];
export const headOptions = [
  { name: "Normal", value: "Normal" },
  { name: "Abnormal", value: "Abnormal" },
];
export const PhysicalOptions = [
  { name: "Normal", value: "Normal" },
  { name: "Abnormal", value: "Abnormal" },
];
export const procedureOptionsData = [
  { name: "Appendectomy", value: "Appendectomy" },
  { name: "Cholecystectomy", value: "Cholecystectomy" },
  { name: "Hernia repair", value: "Hernia repair" },
  { name: "Mastectomy", value: "Mastectomy" },
  { name: "Colon resection", value: "Colon resection" },

  { name: "Joint replacement", value: "Joint replacement" },
  { name: "Biopsy", value: "Biopsy" },
  { name: "Other", value: "OtherstoolOptions" },
];
export const postProcedureStatusOptions = [
  { name: "Recovering well", value: "Recovering well" },
  { name: "Complications observed", value: "Complications observed" },
  { name: "Discharged", value: "Discharged" },
  { name: "Scheduled for follow-up", value: "Scheduled for follow-up" },
  { name: "Other", value: "Others" },
];
export const subjectiveIntervalOptions = [
  {
    name: "Patient reports pain at incision site",
    value: "Patient reports pain at incision site",
  },
  {
    name: "Patient complains of nausea and vomiting",
    value: "Patient complains of nausea and vomiting",
  },
  {
    name: "Patient states improvement in mobility",
    value: "Patient states improvement in mobility",
  },
  { name: "Other", value: "OtherstoolOptions" },
];
export const stoolOptions = [
  { name: "Normal", value: "Normal" },
  { name: "Loose", value: "Loose" },
  { name: "Diarrhea", value: "Diarrhea" },
  { name: "Constipated", value: "Constipated" },
  { name: "Blood in stool", value: "Blood in stool" },
];
export const foleyCatheterOptions = [
  { name: "Foley catheter in place", value: "Foley catheter in place" },
  { name: "Foley catheter removed", value: "Foley catheter removed" },
];
export const assessmentProcess = [
  {
    name: "Stable condition, recovering as expected.",
    value: "Stable condition, recovering as expected.",
  },
  {
    name: "Complications observed, further monitoring required.",
    value: "Complications observed, further monitoring required.",
  },
  {
    name: "Improved symptoms, plan for discharge.",
    value: "Improved symptoms, plan for discharge.",
  },
  {
    name: "Unchanged symptoms, additional interventions needed.",
    value: "Unchanged symptoms, additional interventions needed.",
  },
  {
    name: "Deteriorating condition, urgent interventions required.",
    value: "Deteriorating condition, urgent interventions required.",
  },
];
export const planProcess = [
  { name: "Medication / Adjustment", value: "Medication / Adjustment" },
  { name: "Additional testing", value: "Additional testing" },
  { name: "Referral to specialist", value: "Referral to specialist" },
  { name: "Therapy or rehabilitation", value: "Therapy or rehabilitation" },
  { name: "Discharge planning", value: "Discharge planning" },
];

export const scipMeasuresOptions = [
  {
    name: "SCIP-Inf-1: Prophylactic Antibiotic Received Within One Hour Prior to Surgical Incision",
    value:
      "SCIP-Inf-1: Prophylactic Antibiotic Received Within One Hour Prior to Surgical Incision",
  },
  {
    name: "SCIP-Inf-2: Prophylactic Antibiotic Selection for Surgical Patients",
    value:
      "SCIP-Inf-2: Prophylactic Antibiotic Selection for Surgical Patients",
  },
  {
    name: "SCIP-Inf-3: Prophylactic Antibiotics Discontinued Within 24 Hours After Surgery End Time",
    value:
      "SCIP-Inf-3: Prophylactic Antibiotics Discontinued Within 24 Hours After Surgery End Time",
  },
  {
    name: "SCIP-VTE-1: Venous Thromboembolism Prophylaxis",
    value: "SCIP-VTE-1: Venous Thromboembolism Prophylaxis",
  },
  {
    name: "SCIP-VTE-2: Intensive Care Unit Venous Thromboembolism Prophylaxis",
    value: "SCIP-VTE-2: Intensive Care Unit Venous Thromboembolism Prophylaxis",
  },
  {
    name: "SCIP-VTE-3: Venous Thromboembolism Patients With Warfarin Discharged on Time",
    value:
      "SCIP-VTE-3: Venous Thromboembolism Patients With Warfarin Discharged on Time",
  },
];
export const preOpDiagnosisOptions = [
  { name: "Appendicitis", value: "Appendicitis" },
  { name: "Gallstones", value: "Gallstones" },
  { name: "Hernia", value: "Hernia" },
  { name: "Fracture", value: "Fracture" },
  { name: "Cancer", value: "Cancer" },
  { name: "Infection", value: "Infection" },
];
export const postOpDxOptions = [
  { name: "Successful", value: "Successful" },
  { name: "Complications", value: "Complications" },
  { name: "Improved", value: "Improved" },
  { name: "Unchanged", value: "Unchanged" },
  { name: "Worsened", value: "Worsened" },
];
export const procedureOptionsLRB = [
  { name: "Appendectomy", value: "Appendectomy" },
  { name: "Cholecystectomy", value: "Cholecystectomy" },
  { name: "Hernia repair", value: "Hernia repair" },
  { name: "Fracture fixation", value: "Fracture fixation" },
  { name: "Tumor resection", value: "Tumor resection" },
  { name: "Biopsy", value: "Biopsy" },
];
export const surgeonRoleOptions = [
  { name: "Primary Surgeon", value: "Primary Surgeon" },
  { name: "Assistant Surgeon", value: "Assistant Surgeon" },
  { name: "Anesthesiologist", value: "Anesthesiologist" },
];
export const findingsOptions = [
  { name: "Bleeding", value: "Bleeding" },
  { name: "Inflammation", value: "Inflammation" },
  { name: "Infection", value: "Infection" },
  { name: "Tumor", value: "Tumor" },
  { name: "Adhesions", value: "Adhesions" },
  { name: "Normal", value: "Normal" },
];
export const anesthesiaOptions = [
  { name: "General Anesthesia", value: "General Anesthesia" },
  { name: "Regional Anesthesia", value: "Regional Anesthesia" },
  { name: "Local Anesthesia", value: "Local Anesthesia" },
  { name: "Sedation", value: "Sedation" },
];
export const procedureDetailsOptions = [
  { name: "Adhesiolysis", value: "Adhesiolysis" },
  { name: "Debridement", value: "Debridement" },
  { name: "Excision", value: "Excision" },
  { name: "Fusion", value: "Fusion" },
  { name: "Grafting", value: "Grafting" },
  { name: "Reconstruction", value: "Reconstruction" },
  { name: "Repair", value: "Repair" },
  { name: "Resection", value: "Resection" },
  { name: "Transplantation", value: "Transplantation" },
];
export const complicationsOptions = [
  { name: "Hemorrhage", value: "Hemorrhage" },
  { name: "Infection", value: "Infection" },
  { name: "Organ Injury", value: "Organ Injury" },
  { name: "Anesthesia-related", value: "Anesthesia-related" },
  { name: "Cardiac Event", value: "Cardiac Event" },
];
export const eblOptions = [
  { name: "Minimal (<50 mL)", value: "Minimal (<50 mL)" },
  { name: "Moderate (50-500 mL)", value: "Moderate (50-500 mL)" },
  { name: "Significant (>500 mL)", value: "Significant (>500 mL)" },
];
export const specimensOptions = [
  { name: "Tissue", value: "Tissue" },
  { name: "Fluid", value: "Fluid" },
  { name: "Biopsy", value: "Biopsy" },
  { name: "Cultures", value: "Cultures" },
];
export const pregOptions = [
  { name: "Not Pregnant", value: "Not Pregnant" },
  { name: "Pregnant", value: "Pregnant" },
];
export const famHxOptions = [
  { name: "Hypertension", value: "Hypertension" },
  { name: "Diabetes mellitus", value: "Diabetes mellitus" },
  { name: "Heart disease", value: "Heart disease" },
  { name: "Stroke", value: "Stroke" },
  { name: "Cancer", value: "Cancer" },
  { name: "Thyroid disorder", value: "Thyroid disorder" },
  { name: "Kidney disease", value: "Kidney disease" },
  { name: "Liver disease", value: "Liver disease" },
  { name: "Mental illness", value: "Mental illness" },
];

export const finalplanOptions = [
  { name: "Medication / adjustment", value: "Medication / Adjustment" },
  { name: "Additional testing", value: "Additional testing" },
  { name: "Referral to specialist", value: "Referral to specialist" },
  { name: "Therapy or rehabilitation", value: "Therapy or rehabilitation" },
  { name: "Discharge planning", value: "Discharge planning" },
  { name: "Other", value: "Other (please specify)" },
];
export const finalassessmentOptions = [
  {
    name: "Stable condition, recovering as expected.",
    value: "Stable condition, recovering as expected.",
  },
  {
    name: "Complications observed, further monitoring required.",
    value: "Complications observed, further monitoring required.",
  },
  {
    name: "Improved symptoms, plan for discharge.",
    value: "Improved symptoms, plan for discharge.",
  },
  {
    name: "Unchanged symptoms, additional interventions needed.",
    value: "Unchanged symptoms, additional interventions needed.",
  },
  {
    name: "Deteriorating condition, urgent interventions required.",
    value: "Deteriorating condition, urgent interventions required.",
  },
  { name: "Other", value: "Other (please specify)" },
];
export const educationData = [
  { name: "NONE", value: "NONE" },
  { name: "POST-GRADUATE", value: "POST-GRADUATE" },
  { name: "PRIMARY", value: "PRIMARY" },
  { name: "SECONDARY", value: "SECONDARY" },
  { name: "TERTIARY", value: "TERTIARY" },
];
export const alleryies = [
  { name: "Medication", value: "Medication" },
  { name: "Latex", value: "Latex" },
  { name: "Food", value: "Food" },
];
export const weightLossData = [
  { name: "2-13 Ib (0.91 - 5.9kg)", value: 1 },
  { name: "14-23 Ib (6.35 -10.43kg)", value: 2 },
  { name: "24- 33 Ib (11.02 - 15.16kg)", value: 3 },
  { name: "34 Ib or more (15.42 kg or more)", value: 4 },
  { name: "Unsure", value: 2 },
];
export const mealData = [
  { name: "Had a meal for less than 5 days", value: 0 },
  { name: "Haven’t had a meal for > than 5 days", value: 2 },
];
export const consultationPlans = [
  { name: "Medication / Adjustment", value: "Medication / Adjustment" },
  { name: "Additional Testing", value: "Additional Testing" },
  { name: "Health Education", value: "Health Education" },
  { name: "Therapy or Rehabilitation", value: "Therapy or Rehabilitation" },
  { name: "Imaging", value: "Imaging" },
  { name: "EKG", value: "EKG" },
  { name: "Referral", value: "Referral" },
];
export const anesthesiaTypes = [
  { name: "General Anesthesia", value: "General Anesthesia" },
  { name: "Regional Anesthesia", value: "Regional Anesthesia" },
  { name: "Local Anesthesia", value: "Local Anesthesia" },
  {
    name: "Monitored Anesthesia Care (MAC)",
    value: "Monitored Anesthesia Care (MAC)",
  },
  { name: "Conscious Sedation", value: "Conscious Sedation" },
  { name: "Topical Anesthesia", value: "Topical Anesthesia" },
];
export const anesthesiaStatuses = [
  {
    name: "Unconscious (for general anesthesia)",
    value: "Unconscious (for general anesthesia)",
  },
  {
    name: "Conscious (for regional anesthesia, local anesthesia, and some forms of sedation)",
    value:
      "Conscious (for regional anesthesia, local anesthesia, and some forms of sedation)",
  },
  {
    name: "Sedated (for monitored anesthesia care and conscious sedation)",
    value: "Sedated (for monitored anesthesia care and conscious sedation)",
  },
  {
    name: "Numb (for local anesthesia and regional anesthesia)",
    value: "Numb (for local anesthesia and regional anesthesia)",
  },
  {
    name: "Pain-free (for all types of anesthesia, when properly administered)",
    value:
      "Pain-free (for all types of anesthesia, when properly administered)",
  },
  {
    name: "Immobilized (for general anesthesia and some forms of sedation)",
    value: "Immobilized (for general anesthesia and some forms of sedation)",
  },
];
export const indicationsEGD = [
  {
    name: "Dysphagia (difficulty swallowing)",
    value: "Dysphagia (difficulty swallowing)",
  },
  { name: "Gastrointestinal bleeding", value: "Gastrointestinal bleeding" },
  { name: "Abdominal pain", value: "Abdominal pain" },
  {
    name: "Heartburn or reflux symptoms",
    value: "Heartburn or reflux symptoms",
  },
  { name: "Nausea and vomiting", value: "Nausea and vomiting" },
  { name: "Unexplained weight loss", value: "Unexplained weight loss" },
  {
    name: "Evaluation of esophageal, gastric, or duodenal lesions",
    value: "Evaluation of esophageal, gastric, or duodenal lesions",
  },
  {
    name: "Screening for esophageal, gastric, or duodenal cancer",
    value: "Screening for esophageal, gastric, or duodenal cancer",
  },
  {
    name: "Monitoring of previous gastrointestinal conditions or treatments",
    value: "Monitoring of previous gastrointestinal conditions or treatments",
  },
  {
    name: "Evaluation of suspected gastrointestinal infections or inflammations",
    value:
      "Evaluation of suspected gastrointestinal infections or inflammations",
  },
];
export const generalProceduresEndoscopy = [
  { name: "Patient preparation", value: "Patient preparation" },
  {
    name: "Anesthesia/sedation administration",
    value: "Anesthesia/sedation administration",
  },
  { name: "Endoscope insertion", value: "Endoscope insertion" },
  {
    name: "Visualization and examination",
    value: "Visualization and examination",
  },
  {
    name: "Biopsy and intervention (if needed)",
    value: "Biopsy and intervention (if needed)",
  },
  { name: "Documentation and imaging", value: "Documentation and imaging" },
  { name: "Endoscope withdrawal", value: "Endoscope withdrawal" },
  {
    name: "Recovery and post-procedure care",
    value: "Recovery and post-procedure care",
  },
  {
    name: "Pathology analysis (if biopsies were taken)",
    value: "Pathology analysis (if biopsies were taken)",
  },
];
export const planEndo = [
  {
    name: "Treatment: Specific interventions or therapies aimed at addressing the patient's medical condition or symptoms.",
    value:
      "Treatment: Specific interventions or therapies aimed at addressing the patient's medical condition or symptoms.",
  },
  {
    name: "Medications: Prescriptions for medications to manage symptoms, treat infections, or control chronic conditions.",
    value:
      "Medications: Prescriptions for medications to manage symptoms, treat infections, or control chronic conditions.",
  },
  {
    name: "Procedures: Recommendations for further diagnostic tests, surgical interventions, or therapeutic procedures.",
    value:
      "Procedures: Recommendations for further diagnostic tests, surgical interventions, or therapeutic procedures.",
  },
  {
    name: "Follow-up: Instructions for ongoing monitoring, appointments for follow-up visits, or referrals to other healthcare providers or specialists.",
    value:
      "Follow-up: Instructions for ongoing monitoring, appointments for follow-up visits, or referrals to other healthcare providers or specialists.",
  },
  {
    name: "Lifestyle modifications: Guidance on diet, exercise, stress management, or other lifestyle factors that may impact the patient's health.",
    value:
      "Lifestyle modifications: Guidance on diet, exercise, stress management, or other lifestyle factors that may impact the patient's health.",
  },
  {
    name: "Education: Providing information to the patient and/or their caregivers about their condition, treatment options, and self-care strategies.",
    value:
      "Education: Providing information to the patient and/or their caregivers about their condition, treatment options, and self-care strategies.",
  },
];

export const surgicalHPIDuration = [
  { name: "Acute (hours to days)", value: "Acute (hours to days)" },
  { name: "Subacute (days to weeks)", value: "Subacute (days to weeks)" },
  { name: "Chronic (weeks to months)", value: "Chronic (weeks to months)" },
  {
    name: "Long-standing (months to years)",
    value: "Long-standing (months to years)",
  },
];

export const surgicalHPITiming = [
  { name: "Continuous", value: "Continuous" },
  { name: "Intermittent", value: "Intermittent" },
  { name: "Cyclical", value: "Cyclical" },
  { name: "Seasonal", value: "Seasonal" },
  { name: "Nocturnal", value: "Nocturnal" },
  { name: "Exacerbations", value: "Exacerbations" },
];

export const surgicalHPIModifyingFactors = [
  {
    name: "Exacerbated by physical activity",
    value: "Exacerbated by physical activity",
  },
  { name: "Relieved by rest", value: "Relieved by rest" },
  { name: "Associated with meals", value: "Associated with meals" },
  { name: "Alleviated by medications", value: "Alleviated by medications" },
  {
    name: "Worsened by specific triggers (e.g., environmental factors, stress)",
    value:
      "Worsened by specific triggers (e.g., environmental factors, stress)",
  },
];

export const surgicalHPIAssociatedSignsSymptoms = [
  { name: "Fever or chills", value: "Fever or chills" },
  { name: "Weight changes", value: "Weight changes" },
  { name: "Fatigue or malaise", value: "Fatigue or malaise" },
  { name: "Nausea or vomiting", value: "Nausea or vomiting" },
  { name: "Changes in appetite", value: "Changes in appetite" },
  { name: "Swelling or edema", value: "Swelling or edema" },
  { name: "Localized or radiating pain", value: "Localized or radiating pain" },
  { name: "Changes in bowel habits", value: "Changes in bowel habits" },
  { name: "Changes in urinary habits", value: "Changes in urinary habits" },
  { name: "Bleeding or discharge", value: "Bleeding or discharge" },
  {
    name: "Respiratory symptoms (e.g., cough, dyspnea)",
    value: "Respiratory symptoms (e.g., cough, dyspnea)",
  },
];
export const PsychiatricAssessmentDiagonsisData = [
  {
    name: "Adjustment D/0 (Situational)",
    value: "Adjustment D/0 (Situational)",
  },
  { name: "Anxiety Disorder", value: "Anxiety Disorder" },
  { name: "Substance Abuse/Dep/SUD", value: "Substance Abuse/Dep/SUD" },
  {
    name: "Mood Disorder (Depression/Bipolar D/O)",
    value: "Mood Disorder (Depression/Bipolar D/O)",
  },
  {
    name: "Thought Disorder (Psychosis)",
    value: "Thought Disorder (Psychosis)",
  },
  {
    name: "Psych d/o due to Medical Illnes (Specify Medical Illness)",
    value: "Thought Disorder (Psychosis)",
  },
];

export const MentalstatusexamData = [
  { name: "Apperance", value: "Apperance" },
  { name: "Behavior", value: "Behavior" },
  { name: "Speech", value: "Speech" },
];
export const toothbrushTypeOptions = [
  { name: "Soft", value: "Soft" },
  { name: "Medium", value: "Medium" },
  { name: "Hard", value: "Hard" },
];

export const changeBrushOptions = [
  { name: "3 Months", value: "3 Months" },
  { name: "6 Months", value: "6 Months" },
  { name: "1 Year", value: "1 Year" },
];

export const dentalSymptomsOptions = [
  { name: "Pain", value: "Pain" },
  { name: "Swelling", value: "Swelling" },
  { name: "Shock", value: "Shock" },
  { name: "Toothache", value: "Toothache" },
  { name: "Red and Tender gums", value: "Red and Tender gums" },
  { name: "Loose teeth", value: "Loose teeth" },
  { name: "Redness/Bleeding gum", value: "Redness/Bleeding gum" },
  { name: "Others (Specify)", value: "Others" },
];

export const changeDentalOptions = [
  { name: "Good", value: "Good" },
  { name: "Fair", value: "Fair" },
  { name: "Poor", value: "Poor" },
  { name: "Heavy", value: "Heavy" },
];

export const intraoralExamOptions = [
  { name: "Missing Teeth", value: "Missing Teeth" },
  { name: "Carious lesion", value: "Carious lesion" },
  { name: "Fractured teeth", value: "Fractured teeth" },
];
