import { Box, Button, Center, Flex, Heading, Image } from "@chakra-ui/react";
import { correct } from "../../images/svg";
import { useNavigate } from "react-router-dom";

const Success3 = () => {
  const navgate = useNavigate();
  return (
    <>
      <Flex>
        <Image
          h="87.311px"
          w="92px"
          src="https://i.ibb.co/55YXm4W/small-logo.png"
        />
        <Box h="40px" mt="20px" ml="10px" bg="primary" w="99%"></Box>
      </Flex>{" "}
      <Box m="auto" w={["98%", "98%", "90%", "90%"]} mt="40px">
        <Box
          textAlign="center"
          m="auto"
          w={["100%", "100%", "40%", "40%", "40%", "40%"]}
          p="30px"
        >
          <Center>{correct}</Center>
          <Heading size="sm" m="15px">
            Attendance Successfully Taken
          </Heading>

          <Flex justifyContent="center">
            <Button onClick={() => navgate("/acls-day1")} variant="primary">
              Add New Attendance
            </Button>
          </Flex>
        </Box>
      </Box>
    </>
  );
};
export default Success3;
