export const buttonStyle = {
  baseStyle: { fontWeight: "100" },
  size: {},
  variants: {
    primary: {
      bg: "primaryGrade",
      color: "white",
      boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",

      _hover: {
        bg: "primary",
        color: "white",
      },
    },
    secondary: {
      bg: "transparent",
      color: "white",
      border: "1px solid white",
      boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",

      _hover: {
        bg: "primary",
        color: "white",
        border: "None",
      },
    },
    trans: {
      bg: "transparent",
      color: "primary",
      border: "1px solid #3C2B15",

      _hover: {
        bg: "primary",
        color: "white",
        border: "None",
      },
    },
    menu: {
      bg: "white",
      color: "black",
      boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      _hover: {
        bg: "primaryGrade",
        color: "white",
      },
    },
    empty: {
      bg: "transparent",
      color: "primary",
      _hover: {
        color: "primaryGrade",
      },
    },
  },
  defaultProps: {},
};
