import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { DashboardLayout } from "./components/layout/dashboardLayout ";
import {
  Login,
  ForgetPassword,
  ForgetPasswordChange,
  NotFound,
  Dashboard,
  MissionHome,
  CreateMission,
  ViewMission,
  Users,
  AddUser,
  UserDetails,
  AddPatient,
  Patients,
  PatientDetails,
  AddUser1,
  AddPatient1,
  AddPatient2,
  Task,
  Feeds,
  FeedsArticle,
  FeedsPost,
  Report,
  Referral,
  MissionReport,
  Trend,
  Lab,
  MedMissionReport,
  Publication,
  Profile,
  Surgery,
  PatientSurgery,
  HealthEducation,
  HealthPost,
  Publish,
  Schedule,
  AddSchedule,
  DoctorSchedule,
  UpdatePassword,
  EditSchedule,
  NewAddPatient,
  NewAddPatient1,
  NewAddPatient2,
  SuccessfullyAccount,
  ConsultationHome,
  ConsultationDetails,
  LastVist,
  AddConsultation,
  Messages,
  LastVitials,
  Dispensing,
  AddDrugs,
  AddUserOut,
  AddUserOut1,
  ConsultationFullDetails,
  DrugsDetails,
  EditDrugs,
  DrugsFullDetails,
  Inventory,
  PrintDrugsDetails,
  EditDispencedDrug,
  LabPatientDetails,
  SampleCollected,
  InputResult,
  ResultLab,
  ResultLabDetails,
  LabTestHistory,
  MedicationHistory,
  AddmissionHome,
  AddObeservation,
  ObeservationHistory,
  NewAddPatientSurgery,
  EditMission,
  PreSugery,
  SurgeryOperative,
  SurgeryMain,
  SurgeryProgress,
  SurgeryDetails,
  ObservationDetails,
  PatientDetails2,
  NewAddPatient3,
  SuccessfullyAccountUser,
  PatientDetails3,
  VitalsTreads,
  VitalsTreads2,
  LastMsi,
  SurgeryMain2,
  SurgeryMain3,
  Endoscopy,
  PreEndo,
  EndoOperative,
  EndoMain,
  AnethesiaEvluation,
  AnethesiaEvluation2,
  AnethesiaEvluation3,
  PreAnethesiaEvluation1,
  PreAnethesiaEvluation2,
  PreAnethesiaEvluation3,
  SurgeryNurse,
  PreSugeryNurse,
  SurgeryDischarge,
  EditConsultation,
  PatientConcent,
  ScreeningSummary,
  TriageSummary,
} from "./export";
import NewAddPatientSurgery2 from "./pages/patient/addPatientSurgery";
import Events from "./pages/new-patient/event";
import SuccessfullyAccount222 from "./pages/new-patient/success2";
import Events2 from "./pages/new-patient/event2";
import SuccessfullyAccount334 from "./pages/new-patient/success3";
import Events3 from "./pages/new-patient/event3";
import Events4 from "./pages/new-patient/event4";
import Events5 from "./pages/new-patient/event5";
import Success3 from "./pages/new-patient/success33";
import Success4 from "./pages/new-patient/success4";
import Success5 from "./pages/new-patient/success5";
import Events6 from "./pages/new-patient/event6";
import Success6 from "./pages/new-patient/success6";
import AttendanceEvent from "./pages/attendace";

const token = JSON.parse(localStorage.getItem("token"));
export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {token ? (
        <Route path="/" element={<DashboardLayout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/mission" element={<MissionHome />} />
          <Route path="/mission-create" element={<CreateMission />} />
          <Route path="/mission-view/:id" element={<ViewMission />} />
          <Route path="/mission-edit/:id" element={<EditMission />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users-details/:id" element={<UserDetails />} />
          <Route path="/users-add" element={<AddUser />} />
          <Route path="/users-add1" element={<AddUser1 />} />
          <Route path="/patient-add" element={<AddPatient />} />
          <Route path="/patient-add1" element={<AddPatient1 />} />
          <Route path="/patient-add2" element={<AddPatient2 />} />
          <Route
            path="/patient-surgery/:id/:id2"
            element={<NewAddPatientSurgery2 />}
          />
          <Route path="/patient" element={<Patients />} />
          <Route path="/patient-details/:id" element={<PatientDetails />} />
          <Route
            path="/reassign-patient/:id/:id2"
            element={<PatientDetails3 />}
          />
          <Route
            path="/patient-details/:id/:id2"
            element={<PatientDetails2 />}
          />
          <Route path="/task" element={<Task />} />
          <Route path="/dispensing" element={<Dispensing />} />
          <Route path="/inventory" element={<Inventory />} />
          <Route path="/vitals&treads/:id" element={<VitalsTreads />} />
          <Route path="/vitals&treads2/:id" element={<VitalsTreads2 />} />
          <Route
            path="/dispensing-drugs-details/:id"
            element={<DrugsDetails />}
          />
          <Route path="/inventory-add-drugs" element={<AddDrugs />} />
          <Route
            path="/dispenced-print-drugs/:id"
            element={<PrintDrugsDetails />}
          />
          <Route path="/inventory-edit-drugs/:id" element={<EditDrugs />} />
          <Route
            path="/inventory-drug-details/:id"
            element={<DrugsFullDetails />}
          />
          <Route path="/result" element={<ResultLab />} />
          <Route
            path="/lab-patient-details/:id"
            element={<LabPatientDetails />}
          />
          <Route path="/result-details/:id" element={<ResultLabDetails />} />
          <Route path="/sample-details/:id" element={<InputResult />} />
          <Route path="/sample" element={<SampleCollected />} />
          <Route path="/feeds" element={<Feeds />} />
          <Route path="/feeds-article" element={<FeedsArticle />} />
          <Route path="/feeds-post" element={<FeedsPost />} />
          <Route path="/report" element={<Report />} />
          <Route path="/report-referral" element={<Referral />} />
          <Route path="/report-mission-report" element={<MissionReport />} />
          <Route path="/report-trend" element={<Trend />} />
          <Route
            path="/anethesia-evluation1/:id/:id2"
            element={<AnethesiaEvluation />}
          />
          <Route
            path="/anethesia-evluation2/:id/:id2"
            element={<AnethesiaEvluation2 />}
          />
          <Route
            path="/anethesia-evluation3/:id/:id2"
            element={<AnethesiaEvluation3 />}
          />
          <Route
            path="/anethesia-preevluation1/:id/:id2"
            element={<PreAnethesiaEvluation1 />}
          />
          <Route
            path="/anethesia-preevluation2/:id/:id2"
            element={<PreAnethesiaEvluation2 />}
          />
          <Route
            path="/anethesia-preevluation3/:id/:id2"
            element={<PreAnethesiaEvluation3 />}
          />
          <Route path="/report-lab" element={<Lab />} />
          <Route path="/report-lab" element={<Lab />} />
          <Route path="/report-lab" element={<Lab />} />
          <Route
            path="/report-mission-med-report"
            element={<MedMissionReport />}
          />
          <Route path="/messages" element={<Messages />} />
          <Route path="/report-publication" element={<Publication />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/surgery" element={<Surgery />} />
          <Route path="/surgery-nurse" element={<SurgeryNurse />} />
          <Route path="/surgery-pre/:id/:id2" element={<PreSugery />} />
          <Route
            path="/surgery-nurse-pre/:id/:id2"
            element={<PreSugeryNurse />}
          />
          <Route path="/endoscopy-pre/:id/:id2" element={<PreEndo />} />
          <Route
            path="/surgery-operative/:id/:id2"
            element={<SurgeryOperative />}
          />
          <Route
            path="/endoscopy-operative/:id/:id2"
            element={<EndoOperative />}
          />
          <Route
            path="/surgery-progress/:id/:id2"
            element={<SurgeryProgress />}
          />
          <Route path="/surgery-main/:id/:id2" element={<SurgeryMain />} />
          <Route path="/endoscopy-main/:id/:id2" element={<EndoMain />} />
          <Route path="/surgery-main2/:id/:id2" element={<SurgeryMain2 />} />
          <Route path="/surgery-main3/:id/:id2" element={<SurgeryMain3 />} />
          <Route
            path="/surgery-discharge/:id/:id2"
            element={<SurgeryDischarge />}
          />
          <Route
            path="/surgery-details/:id/:id2"
            element={<SurgeryDetails />}
          />
          <Route path="/endoscopy" element={<Endoscopy />} />
          <Route path="/admission" element={<AddmissionHome />} />
          <Route
            path="/admission-obeservation/:id"
            element={<ObeservationHistory />}
          />
          <Route
            path="/admission-add-observation/:id"
            element={<AddObeservation />}
          />
          <Route
            path="/admission-observation-details/:id"
            element={<ObservationDetails />}
          />
          <Route path="/consultation" element={<ConsultationHome />} />
          <Route path="/consultation-add/:id" element={<AddConsultation />} />
          <Route path="/consultation-edit/:id" element={<EditConsultation />} />
          <Route
            path="/consultation-test-history/:id"
            element={<LabTestHistory />}
          />
          <Route
            path="/consultation-medication-history/:id"
            element={<MedicationHistory />}
          />
          <Route
            path="/consultation-edit-dispenced-drug/:id"
            element={<EditDispencedDrug />}
          />
          <Route path="/consultation/last-visit/:id" element={<LastVist />} />
          <Route path="/consultation/last-msi/:id" element={<LastMsi />} />
          <Route
            path="/consultation/get-vitals-summary/:id"
            element={<LastVitials />}
          />
          <Route
            path="/consultation/get-screening-summary/:id"
            element={<ScreeningSummary />}
          />
          <Route
            path="/consultation/get-triage-summary/:id"
            element={<TriageSummary />}
          />
          <Route
            path="/consultation-details/:id"
            element={<ConsultationDetails />}
          />
          <Route
            path="/consultation-full-details/:id"
            element={<ConsultationFullDetails />}
          />
          <Route path="/surgery-patient" element={<PatientSurgery />} />
          <Route path="/health-edu" element={<HealthEducation />} />
          <Route path="/health-edu-attend" element={<HealthPost />} />
          <Route path="/health-edu-publish" element={<Publish />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/schedule-add" element={<AddSchedule />} />
          <Route
            path="/schedule-edit/:id/:missionData"
            element={<EditSchedule />}
          />
          {/* <Route path="/health-edu" element={<AttendanceEvent />} /> */}
          <Route path="/schedule/doctor/:id" element={<DoctorSchedule />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      ) : (
        <>
          <Route path="/" element={<Login />} />
          <Route
            path="/change-password/:id"
            element={<ForgetPasswordChange />}
          />
          <Route path="/forget-password" element={<ForgetPassword />} />
        </>
      )}
      <Route path="/update-password" element={<UpdatePassword />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/add-users-form" element={<AddUserOut />} />
      <Route path="/add-users-form1" element={<AddUserOut1 />} />
      <Route path="/add-new-patient1" element={<NewAddPatient1 />} />
      <Route path="/patient-concent" element={<PatientConcent />} />
      <Route path="/add-new-patient3" element={<NewAddPatient3 />} />
      <Route path="/add-new-patient" element={<NewAddPatient />} />
      <Route
        path="/new-add-patient-surgery"
        element={<NewAddPatientSurgery />}
      />
      <Route path="/success-account2" element={<SuccessfullyAccountUser />} />
      <Route path="/acls-day1" element={<Events />} />
      <Route path="/hbs-day1" element={<Events2 />} />
      <Route path="/cpd" element={<Events3 />} />
      <Route path="/hbs-day2" element={<Events4 />} />
      <Route path="/acls-day2" element={<Events5 />} />
      <Route path="/stb" element={<Events6 />} />
      <Route path="/add-new-patient2" element={<NewAddPatient2 />} />
      <Route path="/success-account" element={<SuccessfullyAccount />} />
      <Route path="/success-account33" element={<SuccessfullyAccount222 />} />
      <Route path="/success3" element={<Success3 />} />
      <Route path="/success4" element={<Success4 />} />
      <Route path="/success5" element={<Success5 />} />
      <Route path="/success6" element={<Success6 />} />
      <Route path="/success-account333" element={<SuccessfullyAccount334 />} />
      <Route path="/success-account2" element={<SuccessfullyAccountUser />} />
    </Route>
  )
);
//good omo78
