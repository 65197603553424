import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  IconButton,
  useDisclosure,
  DrawerOverlay,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  Image,
} from "@chakra-ui/react";
import { HiOutlineLogout } from "react-icons/hi";
import { HamburgerIcon } from "@chakra-ui/icons";
import ScrollToTop from "../../utils/scrollToTop";
import { logOutApi } from "../../utils/fetch";
import { useMutation } from "react-query";

export const DashboardLayout = () => {
  const token = JSON.parse(localStorage.getItem("token"));

  const { isOpen, onOpen, onClose } = useDisclosure();
  const navgate = useNavigate();
  const btnRef = React.useRef();
  const { mutate } = useMutation(logOutApi, {
    onSuccess: (data) => {
      console.log(data);
    },
  });

  const logoutHandler = () => {
    mutate();
    window.setTimeout(() => {
      window.localStorage.clear();
      window.location.href = `/`;
    }, 1000);
  };

  // const fullname = JSON.parse(localStorage.getItem("fullname"));

  const navigate = useNavigate();
  const menuList = JSON.parse(localStorage.getItem("menu"));

  // if (!token) {
  //     navgate("/");
  //   return <NotFoundServer />;
  // }

  return (
    <Box bg="#E6E6E6" minH="100vh">
      <ScrollToTop />
      <Box
        display={["none", "none", "flex", "flex", "flex", "flex"]}
        p="15px"
        justifyContent="space-between"
      >
        <Image
          h="100px"
          mt="-20px"
          src="https://i.ibb.co/55YXm4W/small-logo.png"
        />
        {token && (
          <Box
            mt="-10px"
            display="flex"
            overflowX="scroll"
            ml="60px"
            css={{
              "&::-webkit-scrollbar": {
                height: "8px",
              },
              "&::-webkit-scrollbar-track": {
                height: "8px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#3C2B15",
                borderRadius: "10px",
              },
            }}
          >
            <Box>
              <Button
                fontSize="12px"
                m="10px"
                onClick={() => navigate(`/`)}
                variant={window.location.pathname === `/` ? "primary" : "menu"}
              >
                Dashboard
              </Button>
            </Box>
            <>
              {menuList &&
                menuList?.map((item) => (
                  <Box key={item?.link}>
                    <Button
                      fontSize="12px"
                      m="10px"
                      key={item?.link}
                      onClick={() => navigate(`${item?.link}`)}
                      variant={
                        window.location.pathname.includes(`${item?.link}`)
                          ? "primary"
                          : "menu"
                      }
                    >
                      {item?.name}
                    </Button>
                  </Box>
                ))}
            </>
            <Box>
              <Button
                fontSize="12px"
                m="10px"
                onClick={logoutHandler}
                variant="menu"
              >
                <HiOutlineLogout size="20px" />
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      <Box w="100%">
        <Box
          display={["flex", "flex", "none", "none", "none", "none"]}
          justifyContent="right"
          mb="10px"
          bg="primary"
          p="10px"
        >
          <Button
            as={IconButton}
            onClick={onOpen}
            aria-label="Options"
            icon={<HamburgerIcon />}
          />
          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
            size="xs"
          >
            <DrawerOverlay />
            <DrawerContent textAlign="left">
              <DrawerCloseButton />
              <Box mt="70px">
                <Box>
                  <Button
                    variant="transpare"
                    onClick={() => {
                      window.location.href = `/`;
                    }}
                  >
                    Dashboard
                  </Button>
                  <br></br>
                </Box>
                {menuList?.map((data) => (
                  <Box key={data?.name}>
                    <Button
                      variant="transpare"
                      onClick={() => {
                        window.location.href = `${data?.link}`;
                      }}
                    >
                      {data?.name}
                    </Button>
                    <br></br>
                  </Box>
                ))}
                <Button variant="transpare" onClick={logoutHandler}>
                  Logout
                </Button>
              </Box>
            </DrawerContent>
          </Drawer>
        </Box>
        <Box p={["8px", "8px", "20px", "20px"]}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
