import React from "react";
import { Center, useBreakpointValue } from "@chakra-ui/react";
import ReactPaginate from "react-paginate";
import styled from "@emotion/styled";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";

const PaginateWrapper = styled.div`
  .pagination-container {
    display: flex;
    align-items: center;
    list-style-type: none;
    border: 1px solid lightgray;
    border-radius: 2px;
    padding: 0;
    flex-wrap: wrap; /* Allows items to wrap on small screens */
  }

  .pagination-item,
  .prev-pagination-item,
  .next-pagination-item {
    cursor: pointer;
    text-align: center;
    border-right: 1px solid lightgray;
    padding: 0;
    flex: 1;
    min-width: 60px; /* Ensures items don’t shrink too much */
    max-width: auto; /* Adjust based on design preference */
  }

  .pagination-link,
  .prev-page-link,
  .next-page-link {
    display: block;
    width: 100%;
    height: 100%;
    padding: 5px 10px;
    text-decoration: none;
  }

  .pagination-item:hover,
  .prev-pagination-item:hover,
  .next-pagination-item:hover {
    background-color: #3c2b15;
    color: #f3f3f3;
  }

  .pagination-ellipsis {
    border-left: 1px solid gray;
  }

  .active {
    background-color: #3c2b15;
    color: #f3f3f3;
  }

  .disabled {
    cursor: not-allowed;
    color: lightgray;
  }
`;

function Pagination({ pageCount, handlePageClick, isLoading }) {
  const fontSize = useBreakpointValue({ base: "12px", md: "16px", lg: "18px" });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Makes the scroll smooth
    });
  };

  return (
    <Center my="10px" onClick={scrollToTop}>
      <PaginateWrapper>
        <ReactPaginate
          containerClassName="pagination-container"
          breakLabel="--"
          pageClassName="pagination-item"
          pageLinkClassName="pagination-link"
          nextClassName="next-pagination-item"
          previousClassName="prev-pagination-item"
          breakClassName="pagination-item"
          breakLinkClassName="pagination-link"
          previousLabel={<FaArrowLeft size={fontSize} />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          nextLabel={<FaArrowRight size={fontSize} />}
          renderOnZeroPageCount={null}
          activeClassName="active"
          disabledClassName="disabled"
          nextLinkClassName="next-page-link"
          previousLinkClassName="prev-page-link"
        />
      </PaginateWrapper>
    </Center>
  );
}

export default Pagination;
